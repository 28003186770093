import React from 'react';
import './Page.css'; // 共通のスタイルシートをインポート
import ProblemImage from '../assets/Problem.jpg'; // 画像をインポート
import AgitationImage from '../assets/Agitation.jpg'; // 画像をインポート
import SolutionImage from '../assets/Solution.jpg'; // 画像をインポート
import OfferImage from '../assets/Offer.jpg'; // 画像をインポート
import NarrowingImage from '../assets/Narrowing.jpg'; // 画像をインポート
import ActionImage from '../assets/Action.jpg'; // 画像をインポート
import Consultation from '../components/Consultation';

function Home() {
  return (
    <div className="page-content">
      <div className="content-section problem-section">
        <div className="image">
          <img src={ProblemImage} alt="ProblemImage" className="home-image problem-image" />
        </div>
        <div className="text">
          <h2>あなたの時間を奪う反復作業、まだ手作業で続けますか？</h2>
          <p>「日々の業務で時間を費やしている反復作業や複雑な手作業、これを自動化してもっと効率的にできたら…。」</p>
        </div>
      </div>
      <div className="content-section agitation-section reverse">
        <div className="image">
          <img src={AgitationImage} alt="AgitationImage" className="home-image agitation-image" />
        </div>
        <div className="text">
          <h2>このままでは、ビジネスの成長を妨げてしまうかもしれません。</h2>
          <p>反復作業や複雑な手作業が増えるたびに、業務が停滞し、生産性が落ち、重要な決断やクリエイティブな業務に集中できない状況にストレスを感じていませんか？</p>
          <p>結果として、ビジネスの成長や利益が損なわれる可能性があります。</p>
        </div>
      </div>
      <div className="content-section solution-section">
       <div className="image">
          <img src={SolutionImage} alt="SolutionImage" className="home-image solution-image" />
        </div>
        <div className="text">
          <h2>Google Apps Scriptで業務を自動化し、生産性を向上させましょう。</h2>
          <p>Google Apps Scriptを活用することで、あなたの業務を自動化し、効率化を図りましょう。</p>
          <p>私たちは、長年の経験をもとに、あらゆるビジネスニーズに合わせたカスタムスクリプトを開発し、運用の手間を大幅に削減します。</p>
        </div>
      </div>
      <div className="content-section offer-section reverse">
        <div className="image">
        <img src={OfferImage} alt="OfferImage" className="home-image offer-image" />
        </div>
        <div className="text">
          <h2>カスタムソリューションで、あなたのビジネスを次のステージへ。</h2>
          <p>私は、経理業務の自動化から顧客管理システムの最適化まで、多岐にわたるソリューションを提供しています。</p>
          <p>まずは、無料の見積もり依頼であなたの課題をお聞かせください。最適なソリューションを提案いたします。</p>
        </div>
      </div>
      <div className="content-section narrowing-section">
        <div className="image">
         <img src={NarrowingImage} alt="NarrowingImage" className="home-image narrowing-image" />
        </div>
        <div className="text">
          <h2>今なら、初回ご相談に限り、特別割引を提供中！</h2>
          <p>今なら、初回のご相談に限り、特別割引を提供しております。</p>
          <p>この機会に、ぜひ一度ご相談ください。</p>
        </div>
      </div>
      <div className="content-section action-section reverse">
        <div className="image">
          <img src={ActionImage} alt="ActionImage" className="home-image action-image" />
        </div>
        <div className="text">      
          <h2>今すぐ見積もり依頼を行い、ビジネスを加速させましょう。</h2>
          <p>お手数ですが、以下のフォームからすぐに見積もり依頼を行ってください。</p>
          <p>お客様のビジネスを次のステージへ導くお手伝いをいたします。</p>
        </div>
      </div>
      <Consultation />
    </div>
  );
}

export default Home;
