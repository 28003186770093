import React from 'react';
import './Page.css'; // 共通のスタイルシートをインポート

function LegalInfo() {
  return (
    <div className="page-content">
      
      <h2>特定商取引法に基づく表記</h2>

      <h3>事業者の名称または個人事業主の氏名</h3>
      <p>中田 龍太郎</p>

      <h3>事業者の所在地</h3>
      <p>以下のフォームよりお問い合わせいただければ、遅滞なく開示します。</p>
      <p><a href="/contact" target="_blank" rel="noopener noreferrer">お問い合わせフォーム</a></p>

      <h3>事業者の電話番号</h3>
      <p>以下のフォームよりお問い合わせいただければ、遅滞なく開示します。</p>
      <p><a href="/contact" target="_blank" rel="noopener noreferrer">お問い合わせフォーム</a></p>

      <h3>事業者の代表者の氏名</h3>
      <p>中田 龍太郎</p>

      <h3>事業の種類</h3>
      <p>情報通信サービス業</p>

      <h3>販売価格</h3>
      <p>各商品とサービスの紹介ページに記載している価格、または個別に提出する見積書に記載している価格とします。</p>

      <h3>販売価格以外に必要な料金</h3>
      <p>インターネット接続料金その他の電気通信回線に関する費用が必要となります。</p>

      <h3>支払方法</h3>
      <p>クレジットカードによる支払（VISA、マスターカード、アメリカン・エキスプレス、ダイナースクラブ）</p>

      <h3>商品またはサービスの提供方法</h3>
      <p>各商品とサービスの紹介ページに記載している提供方法、または個別に提出する見積書に記載している提供方法とします。</p>

      <h3>支払時期</h3>
      <p>各商品の支払い時期は、請求書発行から30日以内にお支払いください。月額料金が発生するサービスについては、料金発生月から1ヶ月ごとに1ヶ月分をお支払いいただきます。</p>
      <p>なお、お支払いの締日や引落し日については、ご利用されるクレジットカード会社まで契約内容についてお問い合わせ下さい。</p>

      <h3>返品に関する条件</h3>
      <p>各商品とサービスの返品は、原則としてお断りしています。</p>

      <h3>不良品の対応に関する条件</h3>
      <p>各商品とサービスの不良品があった場合は、原則として対応いたしません。ただし、各商品において追加作業がない簡易な修正については、支払後30日以内は無料でシステムを修正いたします。</p>

      <h3>サポート・お問い合わせ窓口</h3>
      <p><a href="mailto:contact@nakata.tech">contact@nakata.tech</a></p>

      <h3>その他の注意事項</h3>
      <p>各商品における納品予定のGoogle Apps Scriptの実行は、ご自身の責任で行ってください。</p>
      <p>また、各商品における納品予定のGoogle Apps Scriptのコードには、いくつか制限があります。必ず以下をご確認ください。</p>
      <p><a href="https://developers.google.com/apps-script/guides/services/quotas?hl=ja" target="_blank" rel="noopener noreferrer">Google Apps Scriptの制限について</a></p>

      <h3>取引条件の変更についての事項</h3>
      <p>追加要望があった場合は、再見積もりを行い、改めて見積書を提出いたします。</p>
      <p>また、納品予定のGoogle Apps Scriptのコードをお客様にテスト運用いただくため、見積書に記載の納期は目安であり、当方により自由に変更できるものとします。</p>

    </div>
  );
}

export default LegalInfo;
