import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <ul className="footer-menu">
        <li><a href="/contact">問い合わせ</a></li>
        <li><a href="/privacy-policy">プライバシーポリシー</a></li>
        <li><a href="/terms">利用規約</a></li>
        <li><a href="/specified-commercial-law">特定商取引法に基づく表記</a></li>
      </ul>
      <p>&copy; {new Date().getFullYear()} Nakata Tech. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
