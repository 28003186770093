import React from 'react';
import './Page.css'; // 共通のスタイルシートをインポート

function Contact() {
  return (
    <div style={{ textAlign: 'center', margin: '0' }}>
      <h2>お問い合わせフォーム</h2>
      <div style={{ maxWidth: '640px', margin: '0 auto' }}>
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLSdNGIEcONI0zya3h7PkFw-R7WQCK_IOu2HzBIsHtRpIA1l44A/viewform?embedded=true" 
          width="100%" 
          height="1000" 
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0"
          style={{ display: 'block', margin: '0 auto' }}
        >
          読み込んでいます…
        </iframe>
      </div>
    </div>
  );
}

export default Contact;