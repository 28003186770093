// Apps.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Consultation from './components/Consultation';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import PrivacyPolicy from './pages/privacy-policy';
import Contact from './pages/Contact';
import SpecifiedCommercialLaw from './pages/specified-commercial-law';
import Terms from './pages/terms';
import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/specified-commercial-law" element={<SpecifiedCommercialLaw />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/consultation" element={<Consultation />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
