import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">Nakata Tech</Link>
        <button className="navbar-toggler" onClick={toggleMenu}>
          ☰
        </button>
      </div>
      <div className={`navbar-collapse ${isOpen ? 'show' : ''}`}>
        <ul className="navbar-nav">
          <li><Link to="/" onClick={closeMenu}>ホーム</Link></li>
          {/* <li><Link to="/portfolio" onClick={closeMenu}>ポートフォリオ</Link></li> */}
          <li><Link to="/consultation" onClick={closeMenu}>見積相談</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
