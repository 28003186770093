import React from 'react';
import './Page.css'; // 共通のスタイルシートをインポート

function Portfolio() {
  return (
    <div className="page-content">
      <h1>制作実績</h1>
      <p>過去のプロジェクトを紹介します。</p>
      {/* Firestore からポートフォリオアイテムを表示するコードを追加する予定 */}
    </div>
  );
}

export default Portfolio;
