import React, { useState } from 'react';
// import { ReCAPTCHA } from 'react-google-recaptcha'; // reCAPTCHA ライブラリをインポート
import './Consultation.css';

function Consultation() {
  // const [formData, setFormData] = useState({
  //   name: '',
  //   email: '',
  //   details: ''
  // });
  // const [captchaValue, setCaptchaValue] = useState(null);

  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // };

  // const handleCaptcha = (value) => {
  //   setCaptchaValue(value);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!captchaValue) {
  //     alert('CAPTCHA を完了してください');
  //     return;
  //   }

  //   try {
  //     const response = await fetch('YOUR_GOOGLE_APPS_SCRIPT_URL', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ ...formData, captcha: captchaValue })
  //     });

  //     if (response.ok) {
  //       alert('フォームが送信されました');
  //       setFormData({
  //         name: '',
  //         email: '',
  //         details: ''
  //       });
  //       setCaptchaValue(null);
  //     } else {
  //       alert('フォーム送信に失敗しました');
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //     alert('フォーム送信に失敗しました');
  //   }
  // };

  return (
    <div style={{ textAlign: 'center', margin: '0' }}>
      <h2>見積依頼フォーム</h2>
      <div style={{ maxWidth: '640px', margin: '0 auto' }}>
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLSc8Hmbue3oZkX6JhZNI1oj3i7ewm6h4m-rYTKRJOYUVgHxukg/viewform?embedded=true" 
          width="100%" 
          height="1000" 
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0"
          style={{ display: 'block', margin: '0 auto' }}
        >
          読み込んでいます…
        </iframe>
      </div>
    </div>
    // <div className="page-content">
    //   <h2>見積相談</h2>
    //   <form onSubmit={handleSubmit}>
    //     <label>
    //       名前
    //       <input
    //         type="text"
    //         name="name"
    //         value={formData.name}
    //         onChange={handleChange}
    //         required
    //       />
    //     </label>
    //     <label>
    //       メールアドレス
    //       <input
    //         type="email"
    //         name="email"
    //         value={formData.email}
    //         onChange={handleChange}
    //         required
    //       />
    //     </label>
    //     <label>
    //       詳細
    //       <textarea
    //         name="details"
    //         value={formData.details}
    //         onChange={handleChange}
    //         required
    //       />
    //     </label>
    //     <ReCAPTCHA
    //       sitekey="YOUR_RECAPTCHA_SITE_KEY" // ここに reCAPTCHA サイトキーを設定
    //       onChange={handleCaptcha}
    //     />
    //     <button type="submit">送信</button>
    //   </form>
    // </div>
  );
}

export default Consultation;
